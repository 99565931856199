<template>
	<v-dialog fullscreen v-model="dialog">
		<!-- dialog -->
		<div class="u-dialog-wrap">
			<!--header-->
			<div class="u-dialog-header">
				<h2 class="heading">패키지 할인 설정</h2>
				<v-btn icon plain depressed class="btn-close" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>
			<!--//header-->

			<!--contents-->
			<div class="u-dialog-container">
				<h3 class="desc">
					패키지 할인 가능한 메인 제품 1대 포함하여 신규 렌탈로 2대 이상을
					한번에 구매 시 적용 가능합니다.
				</h3>

				<!-- 적용가능주문 -->
				<v-expansion-panels
					class="panels"
					v-for="(packId, idx) in festaPackIdList"
					:key="idx"
					v-model="openedPanel"
					multiple
				>
					<v-expansion-panel v-if="festaPackIdAvailable(packId)">
						<v-expansion-panel-header>
							{{ festaPackTitle(packId) }} 패키지 가능 주문(<em class="new"
								>신규{{ festaPackData(packId).length }}</em
							>)
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div
								class="row"
								v-for="(item, i) in festaPackData(packId)"
								:key="`item-${i}`"
							>
								<div class="inner">
									<div class="col">
										<div class="type">
											<span class="new">
												신규
											</span>
											<span>
												{{ item.sellTypeText }}
											</span>
											<span v-if="item.GROUP === '1'">
												메인
											</span>
										</div>
										<p class="order" v-if="item.text !== ''">
											{{ item.text }}
										</p>
										<p class="model" v-if="item.MAKTX">
											{{ item.MAKTX }}
										</p>
										<p class="model" v-else>
											{{ item.GOODS_NM }}
										</p>
									</div>
									<div class="col">
										<div v-if="item.type === 'new'">
											<p class="percent" v-if="item.festaActive === false">
												<em>0</em>%
											</p>
											<p class="percent" v-else>
												<em>{{ Number(item.PACKAGE_RATE) }}</em
												>%
											</p>
										</div>
										<div v-else-if="item.type === 'existing'">
											<p class="percent" v-if="item.festaActive === false">
												<em>0</em>%
											</p>
											<p class="percent" v-else>
												<em>{{ Number(item.PACKAGE_RATE) }}</em
												>%
											</p>
										</div>

										<div class="select">
											<v-switch
												inset
												class="switch"
												v-model="item.festaActive"
												@click="festaActiveEvent(item)"
												:label="item.festaActive === false ? `해제` : `적용`"
											>
											</v-switch>
										</div>
									</div>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<!-- //적용가능주문 -->
			</div>
			<!--//contents-->
		</div>
		<!-- //dialog -->

		<!--bottom-->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" depressed large @click="close">
						닫기
					</v-btn>
				</div>
				<div class="column">
					<v-btn
						class="u-btn-active"
						depressed
						large
						:disabled="false"
						@click="save"
					>
						저장
					</v-btn>
				</div>
				<!--//bottom-->
			</div>
		</div>
	</v-dialog>
</template>

<script>
import { getPackCheck } from '@/utils/ec'

export default {
	name: 'PaySameDiscountPayment',
	props: {
		products: { Array, default: () => [] }
	},
	data() {
		return {
			dialog: false,
			// openedPanel: [0, 1, 2],
			openedPanel: [0],
			packData: {
				packApplyPrd: [] // 적용가능한 상품 갯수 (기존 : existing , 신규: new)
			},
			// festaPackIdList: ['048', '049', '050']
			// festaPackIdList: ['048', '049']
			festaPackIdList: ['050']
		}
	},
	methods: {
		getPackCheck,
		festaPackData(packId) {
			return this.packData.packApplyPrd.filter(v => v.PACKAGE_ID === packId)
		},
		festaPackIdAvailable(packId) {
			return this.packData.packApplyPrd.some(v => v.PACKAGE_ID === packId)
		},
		festaPackTitle(packId) {
			if (packId === '048') return '신혼/이사'
			if (packId === '049') return '효도'
			if (packId === '050') return ''
			return ''
		},
		// 오픈
		open() {
			// 오픈시 데이터 초기화
			// this.openedPanel = [0, 1, 2]
			this.openedPanel = [0]
			this.packData.packApplyPrd = []

			// 신규 주문(상품) 추가
			const newData = this.$store.getters['pay/getCombiApplyPrd']
			newData.forEach(v => {
				if (v.type === 'new' && v.sellType === 'Z001') {
					v.etInput2.forEach(d => {
						if (Number(d.PACKAGE_RATE) < 15) return
						this.packData.packApplyPrd.push({
							...v,
							...d,
							festaActive:
								v.PACKAGE_ID === d.PACKAGE_ID && v.I_SEQ === d.I_SEQ
									? v.festaActive
									: false
						})
					})
				}
			})

			this.dialog = true
		},

		// 저장
		async save() {
			let mainPrdcnt = {
				'048': 0,
				'049': 0,
				'050': 0
			}
			let subPrdcnt = {
				'048': 0,
				'049': 0,
				'050': 0
			}
			this.packData.packApplyPrd.forEach(v => {
				// GROUP 1:메인, 2:서브, '':미해당
				if (v.type === 'new' && v.festaActive === true) {
					if (v.GROUP === '1') {
						mainPrdcnt[v.PACKAGE_ID] += 1
					} else {
						subPrdcnt[v.PACKAGE_ID] += 1
					}
				}
			})

			let alertMes = ''
			this.festaPackIdList.forEach(v => {
				const mainCnt = mainPrdcnt[v]
				const subCnt = subPrdcnt[v]
				if (mainCnt + subCnt !== 0 && (mainCnt < 1 || mainCnt + subCnt < 2))
					alertMes =
						'패키지 요건에 충족되지 않습니다. (메인제품 1개 이상 + 부가제품 1개 이상)'
			})

			if (alertMes.length > 0) {
				this.$alert({
					message: alertMes
				})
				return
			}

			let isNotValid = false
			const sameOrCombiPack = this.packData.packApplyPrd.filter(
				v =>
					v.type === 'new' &&
					(v.packActive === true ||
						v.fifteenActive === true ||
						v.combiActive === true) &&
					v.festaActive === true
			)
			isNotValid = sameOrCombiPack.length > 0

			this.packData.packApplyPrd.forEach(v => {
				if (v.type === 'new' && v.festaActive === true) {
					const prdIdx = parseFloat(v.I_SEQ) - 1 // 상품 INDEX
					// 프로모션 체크
					if (
						this.products[prdIdx].payOptions.promotion &&
						!['A141', ''].includes(this.products[prdIdx].payOptions.promotion)
					) {
						isNotValid = true
					} else if (
						this.products[prdIdx].payOptions.downRentalPrepayAmt &&
						this.products[prdIdx].payOptions.downRentalPrepayAmt !== '0'
					) {
						isNotValid = true
					}
				}
			})

			if (isNotValid) {
				this.$alert({
					message:
						'선택되어있는 할인제도와 중복적용 할 수 없습니다. 판매 정책을 확인해주세요'
				})
				return
			}

			// const msg =
			// 	sameOrCombiPack.length > 0
			// 		? '동시구매/결합할인과 중복 적용된 주문이 있습니다. 동시구매/결합할인을 해제한 후 페스타 패키지를 적용하시겠습니까?'
			// 		: '설정된 사항을 저장하시겠습니까?'
			this.$confirm({
				message: '설정된 사항을 저장하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					// if (sameOrCombiPack.length > 0) {
					// 	sameOrCombiPack.forEach(v => {
					// 		v.packActive = false
					// 		v.combiActive = false
					// 	})
					// }

					this.dialog = false
					this.$emit('festaApplyProducts', this.packData)
				}
			})
		},
		close() {
			this.$confirm({
				message:
					'팝업을 닫으면 변경사항이 적용되지 않습니다.\n' +
					'팝업을 닫으시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					this.packData.packApplyPrd = [] // 적용
					this.dialog = false
				}
			})
		},
		festaActiveEvent(item) {
			this.packData.packApplyPrd.forEach((v, index) => {
				if (
					v.I_SEQ === item.I_SEQ &&
					v.PACKAGE_ID !== item.PACKAGE_ID &&
					item.festaActive === true
				) {
					this.$set(
						this.packData.packApplyPrd[index],
						'festaActive',
						!item.festaActive
					)
				}
			})
		}
	}
}
</script>
