<template>
	<v-dialog fullscreen v-model="dialog">
		<!-- dialog -->
		<div class="u-dialog-wrap">
			<!--header-->
			<div class="u-dialog-header">
				<h2 class="heading">결합요금제 설정</h2>
				<v-btn icon plain depressed class="btn-close" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>
			<!--//header-->

			<!--contents-->
			<div class="u-dialog-container">
				<h3 class="desc">
					전월 말일 기준 렌탈/재렌탈 계정 보유시, 할인 적용 가능합니다.
				</h3>

				<!-- 당월적용된주문 -->
				<v-expansion-panels class="panels" v-model="openedPanel1">
					<v-expansion-panel>
						<v-expansion-panel-header>
							기존 주문
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<!-- 데이터 없음 -->
							<div class="row" v-if="combiData.combiExistingPrd.length === 0">
								<p class="nodata">당월 적용된 주문이 없습니다.</p>
							</div>
							<!--// 데이터 없음 -->
							<!-- 데이터 있음 -->
							<div class="row" v-else>
								<div
									class="inner"
									v-for="(prdItem, prdIndex) in combiData.combiExistingPrd"
									:key="prdIndex"
								>
									<div class="col">
										<div class="type">
											<!-- 기존, 30일, 신규 구분 -->
											<span
												v-if="
													prdItem.type === 'existing' &&
														prdItem.combiYn === 'Y' &&
														prdItem.CHK30 === 'X'
												"
												>30일</span
											>
											<span v-else>기존</span>
											<!--// 기존, 30일, 신규 구분 -->
											<!-- 판매 구분 -->
											<span>{{ prdItem.sellTypeText }}</span>
											<!--// 판매 구분 -->
										</div>
										<p
											class="order"
											v-if="prdItem.ORDER_NO && prdItem.ORDER_NO !== ''"
										>
											{{ prdItem.ORDER_NO }}
										</p>
										<p class="order" v-if="prdItem.text !== ''">
											{{ prdItem.text }}
										</p>
										<p class="model" v-if="prdItem.MAKTX !== ''">
											{{ prdItem.MAKTX }}
										</p>
									</div>
									<div
										class="col"
										v-if="
											(prdItem.COMBI_GB === 'O' && prdItem.COMBI_NO === '1') ||
												(prdItem.COMBI_GB === 'O' &&
													prdItem.COMBI_NO === '1' &&
													prdItem.CHK30 === '')
										"
									>
										<p class="percent" style="visibility: hidden">
											<em>{{ Number(prdItem.DC_RATE) }}</em
											>%
										</p>
										<p class="status">비할인</p>
									</div>
									<div
										class="col"
										v-else-if="prdItem.COMBI_NO.includes('100000')"
									>
										<p class="percent">
											<em>{{ Number(prdItem.DC_RATE) }}</em
											>%
										</p>
										<p class="status">적용중</p>
									</div>
								</div>
							</div>
							<!--// 데이터 있음 -->
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<!-- //당월적용된주문 -->

				<!-- 적용가능주문 -->
				<v-expansion-panels class="panels" v-model="openedPanel2">
					<v-expansion-panel>
						<v-expansion-panel-header>
							적용 가능 주문(30일{{ combiData.combiExisting30PrdCnt }}/<em
								class="new"
								>신규{{ combiData.combiApplyPrdCnt }}</em
							>)
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div
								class="row"
								v-for="(applyPrdItem, applyPrdIndex) in combiData.combiApplyPrd"
								:key="applyPrdIndex"
							>
								<div
									class="inner"
									v-if="
										applyPrdItem.COMBI_GB !== '' &&
											getCombiCheck(applyPrdItem.sellType) &&
											!getCombiPrdhaCheck(applyPrdItem.GOODS_CD)
									"
								>
									<div class="col">
										<div class="type">
											<!-- 신규, 30일, 기존 구분 -->
											<span
												class="new"
												v-if="
													applyPrdItem.type === 'new' &&
														applyPrdItem.combiYn === 'Y' &&
														applyPrdItem.CHK30 === undefined
												"
												>신규</span
											>
											<span
												v-else-if="
													applyPrdItem.type === 'existing' &&
														applyPrdItem.combiYn === 'Y' &&
														applyPrdItem.CHK30 === 'X'
												"
												>30일</span
											>
											<span v-else>기존</span>
											<!--// 신규, 30일, 기존 구분 -->

											<span>{{ applyPrdItem.sellTypeText }}</span>
										</div>
										<p
											class="order"
											v-if="
												applyPrdItem.ORDER_NO && applyPrdItem.ORDER_NO !== ''
											"
										>
											{{ applyPrdItem.ORDER_NO }}
										</p>
										<p class="order" v-if="applyPrdItem.text !== ''">
											{{ applyPrdItem.text }}
										</p>
										<p class="model" v-if="applyPrdItem.MAKTX !== ''">
											{{ applyPrdItem.MAKTX }}
										</p>
									</div>
									<div class="col">
										<p class="percent">
											<em v-if="applyPrdItem.combiActive">{{
												Number(applyPrdItem.DC_RATE)
											}}</em>
											<em v-else>0</em>
											%
										</p>
										<div class="select">
											<v-switch
												inset
												class="switch"
												v-model="applyPrdItem.combiActive"
												:label="
													combiData.combiApplyPrd[applyPrdIndex].combiActive ===
													true
														? `적용`
														: `해제`
												"
												@click="toggleAction(applyPrdIndex)"
											>
											</v-switch>
										</div>
									</div>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<!-- //적용가능주문 -->
			</div>
			<!--//contents-->
		</div>
		<!-- //dialog -->

		<!--bottom-->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn large depressed class="u-btn-text" @click="close">닫기</v-btn>
				</div>
				<div class="column">
					<v-btn large depressed class="u-btn-active" @click="save">저장</v-btn>
				</div>
			</div>
		</div>
		<!--//bottom-->
	</v-dialog>
</template>

<script>
import { getCombiCheck, getCombiPrdhaCheck } from '@/utils/ec'

export default {
	name: 'PopPaymentCombined',
	data() {
		return {
			dialog: false,
			openedPanel1: 0,
			openedPanel2: 0,
			combiData: {
				combiExistingPrd: [], // 기존주문 30일 이상 (적용 불가 상품)
				combiExistingPrdCnt: 0, // 기존주문 30일 이상 (적용 불가 상품) 갯수
				combiApplyPrd: [], // 기존주문 30일 이내, 신규주문
				combiApplyPrdCnt: 0, // 신규주문 갯수
				combiExisting30PrdCnt: 0 // 기존주문 30일 이내 (적용 가능 상품) 갯수
			}
		}
	},
	methods: {
		getCombiCheck,
		getCombiPrdhaCheck,
		/**
		 * 적용/해제 하기
		 * @param prd 해당 상품 정보
		 * @param index 해당 상품의 Index
		 */
		toggleAction(index) {
			this.$log('index', index)
			this.$log('index', this.combiData.combiApplyPrd[index].combiActive)
		},

		reset() {
			this.combiData.combiExistingPrd = [] // 기존주문
			this.combiData.combiExistingPrdCnt = 0 // 기존주문 갯수
			this.combiData.combiApplyPrd = [] // 적용
			this.combiData.combiApplyPrdCnt = 0
			this.combiData.combiExisting30PrdCnt = 0
		},

		// 오픈
		open() {
			this.$log('결합요금제 팝업 오픈')
			this.reset()

			// 기존 미결합된 주문
			const combiExistingPrdTemp = this.$store.getters[
				'pay/getCombiExistingPrd'
			]
			combiExistingPrdTemp.forEach(v => {
				if (v.COMBI_GB === 'N') {
					this.combiData.combiApplyPrd.push({ ...v })
				} else {
					this.combiData.combiExistingPrd.push({ ...v })
				}
			})

			// 기존 결합된 주문
			this.combiData.combiExistingPrd.push(
				...this.$store.getters['pay/getCombiExistingApplyPrd']
			)

			// 신규 주문
			const combiApplyPrdTemp = this.$store.getters['pay/getCombiApplyPrd']
			combiApplyPrdTemp.forEach(v => {
				if (v.type === 'new') {
					const idx = v.etInput2.findIndex(
						x => x.I_SEQ === v.I_SEQ && x.COMBI_GB !== ''
					)
					if (idx >= 0) {
						this.combiData.combiApplyPrd.push({
							...v,
							COMBI_GB: v.etInput2[idx].COMBI_GB,
							COMBI_NO: v.etInput2[idx].COMBI_NO,
							DC_RATE: v.etInput2[idx].DC_RATE
						})
					} else {
						this.combiData.combiApplyPrd.push({ ...v })
					}
				}
			})

			// 상품 수량 계산
			this.combiData.combiApplyPrd.forEach(v => {
				if (v.COMBI_GB !== '') {
					if (
						v.type === 'new' &&
						getCombiCheck(v.sellType) &&
						!getCombiPrdhaCheck(v.GOODS_CD)
					) {
						this.combiData.combiApplyPrdCnt += 1
					} else if (
						v.type === 'existing' &&
						v.CHK30 !== undefined &&
						v.CHK30 === 'X' &&
						v.COMBI_GB === 'N'
					) {
						this.combiData.combiExisting30PrdCnt += 1
					} else if (
						v.type === 'existing' &&
						v.CHK30 !== undefined &&
						v.CHK30 === '' &&
						v.COMBI_GB === 'O'
					) {
						this.combiData.combiExistingPrdCnt += 1
					}
				}
			})

			this.dialog = true
		},

		// 저장
		async save() {
			/*
			1. 결합 요금제 적용 상품이 0개, 할인율이 0% 의 경우 저장 가능
			"신규 상품을 적용해야 결합할인 혜택을 받을 수 있습니다. 상품을 추가로 적용해주세요."
			2. 기존 주문만 적용한 경우
			"설정된 사항을 저장하시겠습니까?
			 */
			// 기존 주문 적용 갯수
			let existingApplyCount = 0
			// 신규 주문 적용 갯수
			let newApplyCount = 0
			// 신규 적용 건 이면서 dc_rate 0.00
			let dcRateSumPrice = 0
			// 30일 or 신규 미적용 Count
			let isNotActiveCount = 0
			// 신규 건 적용상태 && dc_rate > 0 Count
			let isEffectivCount = 0
			// 신규 건 적용상태 && dc_rate 0.00 Count
			let isEffectiv2Count = 0
			await this.combiData.combiApplyPrd.forEach((v, index) => {
				this.$log('this.combiData.combiApplyPrd', index)
				if (
					v.type === 'new' &&
					v.combiActive === true &&
					Number(v.DC_RATE) > 0
				) {
					isEffectivCount += 1
				}
				if (v.type === 'new' && v.combiActive === true) {
					if (Number(v.DC_RATE) === 0 || v.DC_RATE === '') {
						isEffectiv2Count += 1
					}
					dcRateSumPrice +=
						Number(v.DC_RATE) === 0 || v.DC_RATE === '' ? 0 : Number(v.DC_RATE)
				}
				if (v.type === 'new' && v.combiActive === true) {
					newApplyCount += 1
				} else if (v.type === 'existing' && v.combiActive === true) {
					existingApplyCount += 1
				} else {
					isNotActiveCount += 1
				}
			})
			// 신규 주문 적용 없음, 기존 주문 적용 있음
			if (newApplyCount === 0 && existingApplyCount > 0) {
				this.$alert({
					message:
						'신규 상품을 적용해야 결합할인 혜택을 받을 수 있습니다. 상품을 추가로 적용해주세요.'
				})
				return
				/* - 비할인계정+기존주문만 적용했을때  
				   - 비할인계정만 적용했을때
			*/
			} else if (
				dcRateSumPrice === 0 &&
				isNotActiveCount > 0 &&
				this.combiData.combiExistingPrd.length === 0 &&
				isNotActiveCount !== this.combiData.combiApplyPrd.length
			) {
				this.$alert({
					message:
						'신규 주문 중 할인이 가능한 계정은 필수로 적용해야 합니다.<br>할인 가능한 계정을 추가로 적용해 주세요.'
				})
				return
				// 비할인계정 주문을 적용하지 않았을때
			} else if (
				isEffectivCount > 0 &&
				this.combiData.combiExistingPrd.length === 0 &&
				isEffectiv2Count === 0
			) {
				this.$alert({
					message:
						'비할인 계정은 필수로 적용해야 합니다<br>적용 시 0% 할인으로 표기되는 계정을 추가로 적용해 주세요.'
				})
				return
			}

			const festaPack = this.combiData.combiApplyPrd.filter(
				v =>
					v.type === 'new' &&
					v.combiActive === true &&
					(v.festaActive === true || v.fifteenActive === true)
			)

			// 페스타 패키지와 중복 적용 불가능
			if (festaPack.length > 0) {
				this.$alert({
					message:
						'선택되어있는 할인제도와 중복적용 할 수 없습니다. 판매 정책을 확인해주세요 '
				})
				return
			}
			this.$confirm({
				message: '설정된 사항을 저장하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					this.dialog = false
					this.$emit('combiApplyProducts', this.combiData)
				}
			})
		},

		// 닫기
		close() {
			this.$confirm({
				message:
					'팝업을 닫으면 변경사항이 적용되지 않습니다.\n' +
					'팝업을 닫으시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					this.reset()
					this.dialog = false
				}
			})
		}
	}
}
</script>
