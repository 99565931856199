<template>
	<v-dialog fullscreen v-model="dialog">
		<!-- dialog -->
		<div class="u-dialog-wrap">
			<!--header-->
			<div class="u-dialog-header">
				<h2 class="heading">코웨이 패키지 할인 설정</h2>
				<v-btn icon plain depressed class="btn-close" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>
			<!--//header-->

			<!--contents-->
			<div class="u-dialog-container">
				<h3 class="desc">
					코웨이 패키지 할인 가능한 제품으로 신규 렌탈로 2대 이상을 한번에 구매
					시 적용 가능합니다.
				</h3>

				<!-- 적용가능주문 -->
				<v-expansion-panels class="panels" v-model="openedPanel">
					<v-expansion-panel>
						<v-expansion-panel-header>
							코웨이 패키지 할인 가능 주문(<em class="new"
								>신규{{ packData.packApplyPrdCnt }}</em
							>)
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<template v-for="(item, i) in packData.packApplyPrd">
								<div class="row" :key="i" v-if="item.PACKAGE_ID === '050'">
									<div class="inner">
										<div class="col">
											<div class="type">
												<span class="new">
													신규
												</span>
												<span>
													{{ item.sellTypeText }}
												</span>
											</div>
											<p class="order" v-if="item.text !== ''">
												{{ item.text }}
											</p>
											<p class="model" v-if="item.MAKTX">
												{{ item.MAKTX }}
											</p>
											<p class="model" v-else>
												{{ item.GOODS_NM }}
											</p>
										</div>
										<div class="col">
											<div>
												<p class="percent" v-if="item.fifteenActive === false">
													<em>0</em>%
												</p>
												<p class="percent" v-else>
													<em>{{ Number(item.PACKAGE_RATE) }}</em
													>%
												</p>
											</div>

											<div class="select">
												<v-switch
													inset
													class="switch"
													v-model="item.fifteenActive"
													:label="
														item.fifteenActive === false ? `해제` : `적용`
													"
												>
												</v-switch>
											</div>
										</div>
									</div>
								</div>
							</template>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<!-- //적용가능주문 -->
			</div>
			<!--//contents-->
		</div>
		<!-- //dialog -->

		<!--bottom-->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" depressed large @click="close">
						닫기
					</v-btn>
				</div>
				<div class="column">
					<v-btn
						class="u-btn-active"
						depressed
						large
						:disabled="false"
						@click="save"
					>
						저장
					</v-btn>
				</div>
				<!--//bottom-->
			</div>
		</div>
	</v-dialog>
</template>

<script>
export default {
	name: 'PaySameDiscountPayment',
	props: {
		products: { Array, default: () => [] }
	},
	data() {
		return {
			dialog: false,
			openedPanel: 0,
			packData: {
				packApplyPrd: [], // 적용가능한 상품 갯수 (기존 : existing , 신규: new)
				packApplyPrdCnt: 0 // 신규 상품(주문) 갯수
			}
		}
	},
	methods: {
		// 오픈
		open() {
			// 오픈시 데이터 초기화
			this.packData.packApplyPrd = []
			this.packData.packApplyPrdCnt = 0

			// 신규 주문(상품) 추가
			const newData = this.$store.getters['pay/getCombiApplyPrd']
			newData.forEach(v => {
				if (v.type === 'new') {
					const idx = v.etInput2.findIndex(
						x =>
							x.I_SEQ === v.I_SEQ &&
							x.PACKAGE_ID === '050' &&
							Number(x.PACKAGE_RATE) >= 15
					)

					if (idx >= 0 && v.sellType === 'Z001') {
						this.packData.packApplyPrd.push({
							...v,
							...v.etInput2[idx]
						})
					} else {
						this.packData.packApplyPrd.push({
							...v
						})
					}
				}
			})

			// 상품 수량 계산
			this.packData.packApplyPrd.forEach(v => {
				if (v.type === 'new' && v.PACKAGE_ID === '050') {
					this.packData.packApplyPrdCnt += 1
				}
			})

			this.dialog = true
		},

		// 저장
		async save() {
			let newPrdCnt = 0
			await this.packData.packApplyPrd.forEach(v => {
				// 신규 주문 수
				if (v.type === 'new' && v.fifteenActive === true) {
					newPrdCnt += 1
				}
			})
			// 상품 2개 이하 적용 시
			if (newPrdCnt !== 0 && newPrdCnt < 2) {
				this.$alert({
					message:
						'최소 2개 상품을 적용해야 코웨이 패키지 할인 혜택을 받을 수 있습니다. 상품을 추가로 적용해주세요.'
				})
				return
			}

			let isNotValid = false
			const festaOrSamePack = this.packData.packApplyPrd.filter(
				v =>
					v.type === 'new' &&
					v.fifteenActive === true &&
					(v.combiActive === true ||
						v.packActive === true ||
						v.festaActive === true)
			)

			// 동시구매 or 페스타 패키지 중복 적용 불가능
			if (festaOrSamePack.length > 0) {
				isNotValid = true
			}

			const fifteenTrueData = this.packData.packApplyPrd.filter(
				v => v.type === 'new' && v.fifteenActive === true
			)

			fifteenTrueData.forEach(v => {
				const prdIdx = parseFloat(v.I_SEQ) - 1 // 상품 INDEX
				// 프로모션 체크
				if (
					this.products[prdIdx].payOptions.promotion &&
					!['A141', 'A317', 'A339', ''].includes(
						this.products[prdIdx].payOptions.promotion
					)
				) {
					isNotValid = true
				} else if (
					this.products[prdIdx].payOptions.downRentalPrepayAmt &&
					this.products[prdIdx].payOptions.downRentalPrepayAmt !== '0'
				) {
					isNotValid = true
				}
			})

			if (isNotValid) {
				this.$alert({
					message:
						'선택되어있는 할인제도와 중복적용 할 수 없습니다. 판매 정책을 확인해주세요'
				})
				return
			}

			this.$confirm({
				message: '설정된 사항을 저장하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					// 코웨이 패키지 할인 적용 아니면 etInput2 050으로 담아 둔것 초기화
					const newData = this.$store.getters['pay/getCombiApplyPrd']
					this.packData.packApplyPrd.forEach((v, index) => {
						if (v.type === 'new' && v.fifteenActive === false) {
							const idx = newData.findIndex(d => d.I_SEQ === v.I_SEQ)
							this.packData.packApplyPrd[index] = newData[idx]
							this.packData.packApplyPrd[index].fifteenActive = false
						}
					})
					this.dialog = false
					this.$emit('fifteenApplyProducts', this.packData)
				}
			})
		},
		close() {
			this.$confirm({
				message:
					'팝업을 닫으면 변경사항이 적용되지 않습니다.\n' +
					'팝업을 닫으시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					this.packData.packApplyPrd = [] // 적용
					this.packData.packApplyPrdCnt = 0
					this.dialog = false
				}
			})
		}
	}
}
</script>
