<template>
	<v-dialog fullscreen v-model="dialog">
		<!-- dialog -->
		<div class="u-dialog-wrap">
			<!--header-->
			<div class="u-dialog-header">
				<h2 class="heading">동시구매할인요금제 설정</h2>
				<v-btn icon plain depressed class="btn-close" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>
			<!--//header-->

			<!--contents-->
			<div class="u-dialog-container">
				<h3 class="desc">
					당월 2대 이상 렌탈/재렌탈/일시불 주문 시 할인 적용 가능합니다.
				</h3>

				<!-- 적용가능주문 -->
				<v-expansion-panels class="panels" v-model="openedPanel">
					<v-expansion-panel>
						<v-expansion-panel-header>
							적용 가능 주문(당월{{ packData.packExistingPrdCnt }}/<em
								class="new"
								>신규{{ packData.packApplyPrdCnt }}</em
							>)
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div
								class="row"
								v-for="(item, i) in packData.packApplyPrd"
								:key="i"
							>
								<div
									class="inner"
									v-if="packIdCheck(item) && !getPackPrdhaCheck(item.GOODS_CD)"
								>
									<div class="col">
										<div class="type">
											<span class="new" v-if="item.type === 'new'">
												신규
											</span>
											<span v-else-if="item.type === 'existing'">
												당월
											</span>
											<span v-else>기존</span>
											<span>
												{{ item.sellTypeText }}
											</span>
										</div>
										<p
											class="order"
											v-if="item.ORDER_NO && item.ORDER_NO !== ''"
										>
											{{ item.ORDER_NO }}
										</p>
										<p class="order" v-if="item.text !== ''">
											{{ item.text }}
										</p>
										<p class="model" v-if="item.MAKTX">
											{{ item.MAKTX }}
										</p>
										<p class="model" v-else>
											{{ item.GOODS_NM }}
										</p>
									</div>
									<div class="col">
										<div v-if="item.type === 'new'">
											<p class="percent" v-if="item.packActive === false">
												<em>0</em>%
											</p>
											<p class="percent" v-else>
												<em>{{ Number(item.PACKAGE_RATE) }}</em
												>%
											</p>
										</div>
										<div v-else-if="item.type === 'existing'">
											<p class="percent" v-if="item.packActive === false">
												<em>0</em>%
											</p>
											<p class="percent" v-else>
												<em>{{ Number(item.PACKAGE_RATE) }}</em
												>%
											</p>
										</div>

										<div class="select">
											<v-switch
												inset
												class="switch"
												v-model="item.packActive"
												:label="item.packActive === false ? `해제` : `적용`"
											>
											</v-switch>
										</div>
									</div>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<!-- //적용가능주문 -->
			</div>
			<!--//contents-->
		</div>
		<!-- //dialog -->

		<!--bottom-->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" depressed large @click="close">
						닫기
					</v-btn>
				</div>
				<div class="column">
					<v-btn
						class="u-btn-active"
						depressed
						large
						:disabled="false"
						@click="save"
					>
						저장
					</v-btn>
				</div>
				<!--//bottom-->
			</div>
		</div>
	</v-dialog>
</template>

<script>
import { getPackCheck, getPackPrdhaCheck } from '@/utils/ec'

export default {
	name: 'PaySameDiscountPayment',
	data() {
		return {
			dialog: false,
			openedPanel: 0,
			packData: {
				packApplyPrd: [], // 적용가능한 상품 갯수 (기존 : existing , 신규: new)
				packApplyPrdCnt: 0, // 신규 상품(주문) 갯수
				packExistingPrdCnt: 0 // 기존주문(당월) 상품(주문) 갯수
			}
		}
	},
	methods: {
		getPackCheck,
		getPackPrdhaCheck,
		// 오픈
		open() {
			// 오픈시 데이터 초기화
			this.packData.packApplyPrd = []
			this.packData.packApplyPrdCnt = 0
			this.packData.packExistingPrdCnt = 0

			// 기존 주문(상품) 추가
			const existingData = this.$store.getters['pay/getPackagePrd']
			existingData.forEach(v => {
				this.packData.packApplyPrd.push({
					...v
				})
			})

			// 신규 주문(상품) 추가
			const newData = this.$store.getters['pay/getCombiApplyPrd']
			newData.forEach(v => {
				if (!getPackPrdhaCheck(v.GOODS_CD)) {
					if (v.type === 'new' && v.PACKAGE_ID !== '047') {
						const idx = v.etInput2.findIndex(
							x => x.I_SEQ === v.I_SEQ && x.PACKAGE_ID === '047'
						)
						if (idx >= 0) {
							this.packData.packApplyPrd.push({
								...v,
								...v.etInput2[idx]
							})
						} else {
							this.packData.packApplyPrd.push({
								...v
							})
						}
					} else {
						this.packData.packApplyPrd.push({
							...v
						})
					}
				}
			})

			// 상품 수량 계산
			this.packData.packApplyPrd.forEach(v => {
				if (v.type === 'new' && v.PACKAGE_ID === '047') {
					this.packData.packApplyPrdCnt += 1
				} else if (v.type === 'existing') {
					this.packData.packExistingPrdCnt += 1
				}
			})

			this.dialog = true
		},

		// 저장
		async save() {
			let beforePrdCnt = 0
			let newPrdCnt = 0
			await this.packData.packApplyPrd.forEach(v => {
				// 신규 주문 수
				if (v.type === 'new' && v.packActive === true) {
					newPrdCnt += 1
				}
				// 기존 주문 수
				if (v.type === 'existing' && v.packActive === true) {
					beforePrdCnt += 1
				}
			})
			// 신규상품 적용 안 할 시
			if (beforePrdCnt !== 0 && newPrdCnt === 0) {
				this.$alert({
					message:
						'신규 상품을 적용해야 동시구매 할인혜택을 받을 수 있습니다. 상품을 추가로 적용해주세요.'
				})
				return
			}
			// 상품 2개 이하 적용 시
			// 코웨이 패키지 활성화 되어있는 경우는 예외적으로 1개도 선택 가능
			const exceptCntChk = this.packData.packApplyPrd.some(
				v =>
					v.type === 'new' &&
					v.packActive === false &&
					(v.festaActive === true || v.fifteenActive === true)
			)
			if (
				newPrdCnt + beforePrdCnt !== 0 &&
				newPrdCnt + beforePrdCnt < 2 &&
				!exceptCntChk
			) {
				this.$alert({
					message:
						'최소 2개 상품을 적용해야 동시구매할인 혜택을 받을 수 있습니다. 상품을 추가로 적용해주세요.'
				})
				return
			}

			const festaPack = this.packData.packApplyPrd.filter(
				v =>
					v.type === 'new' &&
					v.packActive === true &&
					(v.festaActive === true || v.fifteenActive === true)
			)

			// 페스타 패키지와 중복 적용 불가능
			if (festaPack.length > 0) {
				this.$alert({
					message:
						'선택되어있는 할인제도와 중복적용 할 수 없습니다. 판매 정책을 확인해주세요 '
				})
				return
			}
			this.$confirm({
				message: '설정된 사항을 저장하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					// 동시구매 패키지 적용 아니면 etInput2 047로 담아 둔것 초기화
					const newData = this.$store.getters['pay/getCombiApplyPrd']
					this.packData.packApplyPrd.forEach((v, index) => {
						if (v.type === 'new' && v.packActive === false) {
							const idx = newData.findIndex(d => d.I_SEQ === v.I_SEQ)
							this.packData.packApplyPrd[index] = newData[idx]
							this.packData.packApplyPrd[index].packActive = false
						}
					})
					this.dialog = false
					this.$emit('packApplyProducts', this.packData)
				}
			})
		},
		close() {
			this.$confirm({
				message:
					'팝업을 닫으면 변경사항이 적용되지 않습니다.\n' +
					'팝업을 닫으시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			}).then(res => {
				if (res) {
					// 확인
					this.packData.packApplyPrd = [] // 적용
					this.packData.packApplyPrdCnt = 0
					this.packData.packExistingPrdCnt = 0
					this.dialog = false
				}
			})
		},
		// 페스타 패키지로 인해 기존 계정은 페스타 패키지ID도 보여주어야한다.
		packIdCheck(item) {
			if (item.type === 'new') return item.PACKAGE_ID === '047'
			return ['047', '048', '049', '050'].includes(item.PACKAGE_ID)
		}
	}
}
</script>
